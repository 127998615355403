import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, FormGroup, Modal } from "react-bootstrap";
import { BASEURL } from "../BaseUrl";
import { DataGrid } from "@mui/x-data-grid";
import TitleComp from "../Component/TitleComp";
import Loader from "../Component/Loader";
import Sidebaar from "../Component/Sidebaar";

function Gallery() {
  const getRowId = (resident) => resident._id;

  const [name, setName] = useState("");
  const [countrydata, setcountrydata] = useState([]);
  const [gallerydata, setgallerydata] = useState([]);

  const [id, setid] = useState("");
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...files, ...selectedFiles]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
    let formdata = new FormData();
    formdata.append("hoteldetail", name);

    // Append each file to the FormData under the 'images' key
    for (let i = 0; i < files.length; i++) {
      formdata.append("images", files[i]);
    }

    axios
      .post(`${BASEURL}/gallery`, formdata)
      .then((res) => {
        console.log(res);
        if (res.data !== null) {
          alert("Data Submit Successfully");
          setOpen(false);
          allcountrydata();
          allgallerydata();
          handleClose();
        }
      })
      .catch((err) => {
        setOpen(false);
        console.error(err);
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setOpen(true);
    let formdata = new FormData();
    formdata.append("name", name);

    // Append each file to the FormData under the 'images' key
    for (let i = 0; i < files.length; i++) {
      formdata.append("images", files[i]);
    }

    axios
      .put(`${BASEURL}/gallery/${id}`, formdata)
      .then((res) => {
        console.log(res);
        if (res.data !== null) {
          alert("Data Updated Successfully");
          setOpen(false);
          allcountrydata();

          handleClose();
        }
      })
      .catch((err) => {
        setOpen(false);
        console.error(err);
      });
  };

  // ... (remaining code)

  const handleClose = () => {
    setShow(false);
    setid("");
    setName("");
  };

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const allcountrydata = () => {
    setOpen(true);
    axios.get(`${BASEURL}/allhotelsdetail`).then((res) => {
      console.log(res.data);
      setcountrydata(res.data);
      setOpen(false);
    });
  };

  const allgallerydata = () => {
    setOpen(true);
    axios.get(`${BASEURL}/allgallery`).then((res) => {
      console.log(res.data);
      setgallerydata(res.data);
      setOpen(false);
    });
  };

  const handleShow = (id) => {
    setShow(true);
    setid(id);
  };

  //   useEffect(() => {
  //     if (id) {
  //       handleEdit();
  //     }
  //   }, [id]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) => {
        return params.row?.hoteldetail[0]?.name;
      },
    },

    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <button onClick={(e) => handleDelete(e, id)} className="btn">
            <i class="fa-solid fa-trash"></i>
          </button>
        );
      },
    },
  ];

  const handleDelete = (e, id) => {
    e.preventDefault();
    setOpen(true);
    axios.delete(`${BASEURL}/gallery/${id}`).then((res) => {
      if (res.data !== null) {
        alert("Data Deleted Successfully");
        allgallerydata();
        setOpen(false);
      }
    });
  };

  useEffect(() => {
    allcountrydata();
    allgallerydata();
  }, []);

  return (
    <>
      <Loader open={open} />
      <Sidebaar
        content={
          <>
            <section className="">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <TitleComp title="Gallery" />
                  </div>
                  <Form className="bg-white shadow my-3 p-3 rounded-3">
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Select Hotel Detail
                          </label>
                          <select
                            name=""
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control form-select"
                            id=""
                          >
                            <option value="" className="d-none">
                              Select hotelDetail
                            </option>
                            {countrydata?.map((item) => {
                              return (
                                <>
                                  <option value={item._id} className="">
                                    {item.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </div>
                      <div className="col-md-4">
                        <FormGroup>
                          <label htmlFor="">Upload</label>
                          <input
                            type="file"
                            className="form-control"
                            name="file"
                            accept=".png , .jpg , .jpeg , .gif ,.avif"
                            multiple
                            onChange={handleFileChange}
                          />
                        </FormGroup>
                      </div>

                      <div className="col-md-4">
                        <button
                          className="btn bg-success mt-3 text-white"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                  <div className="col-md-12 mt-3">
                    {countrydata.length > 0 ? (
                      <Box sx={{ height: 500, width: "100%" }}>
                        <DataGrid
                          rows={gallerydata}
                          columns={columns}
                          getRowId={getRowId}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[10]}
                          disableRowSelectionOnClick
                        />
                      </Box>
                    ) : (
                      <div>Loading or no data available</div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      />
    </>
  );
}

export default Gallery;
