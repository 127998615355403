import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { BASEURL } from "../BaseUrl";
import TitleComp from "../Component/TitleComp";
import Sidebaar from "../Component/Sidebaar";
import ExcelJS from "exceljs";

function Booking() {
  const [countrydata, setcountrydata] = useState([]);
  const headers = {
    "Content-Type": "application/json",
  };

  const navigate = useNavigate();

  const allcountrydata = () => {
    axios.get(`${BASEURL}/bookings`, { headers }).then((res) => {
      console.log(res.data);
      setcountrydata(res.data?.reverse());
    });
  };

  const handleDownloadExcel = () => {
    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Bookings");

    // Define the columns in the Excel sheet
    const excelColumns = [
      { header: "Name", key: "name", width: 15 },
      { header: "Comes", key: "comes", width: 15 },
      { header: "Destination", key: "destination", width: 15 },
      { header: "Departure", key: "departure", width: 15 },
      { header: "Rooms", key: "rooms", width: 15 },

      { header: "guest", key: "guest", width: 15 },

      { header: "Phone", key: "phone", width: 15 },
      { header: "Email", key: "email", width: 20 },
    ];

    // Add columns to the worksheet
    worksheet.columns = excelColumns;

    // Add data to the worksheet
    countrydata.forEach((row) => {
      worksheet.addRow(row);
    });

    // Create a blob with the Excel data
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a download link and trigger a click event
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "bookings.xlsx";
      link.click();
    });
  };

  const getRowId = (resident) => resident._id;

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "comes",
      headerName: "Comes",
      flex: 1,
    },
    {
      field: "rooms",
      headerName: "Rooms",
      flex: 1,
    },
    {
      field: "guest",
      headerName: "Guest",
      flex: 1,
    },
    {
      field: "destination",
      headerName: "Destination",
      flex: 1,
    },

    {
      field: "departure",
      headerName: "Departure",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "phone",
      flex: 1,
    },
    {
      field: "email",
      headerName: "email",
      flex: 2,
    },

    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <button onClick={() => handleDelete(id)} className="btn">
            <i class="fa-solid fa-trash"></i>
          </button>
        );
      },
    },
  ];

  const handleDelete = (id) => {
    axios.delete(`${BASEURL}/bookings/${id}`).then((res) => {
      alert("delete Data Succesffully");
      allcountrydata();
    });
  };

  useEffect(() => {
    allcountrydata();
  }, []);
  return (
    <Sidebaar
      content={
        <>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <TitleComp title="Booking List" />
                </div>

                <div className="col-md-12 mt-3">
                  <div className="">
                    <button
                      onClick={handleDownloadExcel}
                      className="btn bg-blue text-white mb-3"
                    >
                      Download Excel
                    </button>
                  </div>
                  {countrydata.length > 0 ? (
                    <Box sx={{ height: 500, width: "100%" }}>
                      <DataGrid
                        rows={countrydata}
                        columns={columns}
                        getRowId={getRowId}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                      />
                    </Box>
                  ) : (
                    <div>Loading or no data available</div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      }
    />
  );
}

export default Booking;
