import React, { useEffect, useState } from "react";
import travel from "../Image/travel.avif";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASEURL } from "../BaseUrl";
import { Form } from "react-bootstrap";

function Login() {
  const navigate = useNavigate();

  const [username, setusername] = useState("");
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);

  const [password, setpassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const headers = {
    "Content-Type": "application/json",
  };
  const handlePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "newPassword") {
      setShowNewPassword(!showNewPassword);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (forgotPasswordMode) {
      // Handle password reset logic here
      // Call the /updatepassword API with the necessary parameters
      axios
        .put(`${BASEURL}/updatepassword`, {
          username: username,
          currentPassword: password, // Assuming the current password is used for verification
          newPassword: newPassword, // Assuming you've added a new state for newPassword
        })
        .then((res) => {
          console.log(res);
          alert(res.data.message);
          // Handle success, e.g., show a success message to the user
        })
        .catch((error) => {
          console.error("Error during password reset:", error);
          alert(error.response.data.message);
          // Handle errors, e.g., show an error message to the user
        });
    } else {
      // Handle login logic here
      // Call the /login API as before
      axios
        .post(`${BASEURL}/login`, { username, password }, { headers })
        .then((res) => {
          console.log(res);
          // Check for successful response status
          if (res.status === 200 || res.status === 201) {
            // Handle success, navigate to "/country" for example
            navigate("/booking");
            localStorage.setItem("login", true);
          } else {
            // Handle other status codes
            alert("Unexpected response status: " + res.status);
          }
        })
        .catch((error) => {
          // Handle errors as before
          console.error("Error during login:", error);
          if (error.response && error.response.status === 401) {
            alert("Authentication failed. Please check your credentials.");
          } else {
            alert("An unexpected error occurred. Please try again later.");
          }
        });
    }
  };

  let login = localStorage.getItem("login");

  console.log("loginn", login);

  useEffect(() => {
    if (login == "true") {
      navigate("/booking");
    }
  }, []);

  return (
    <section class="loginbg">
      <div class="container-fluid">
        <div
          class="align-items-center justify-content-center  row "
          style={{ height: "100vh" }}
        >
          <div class="col-md-8">
            <div
              class="align-items-center shadow bg-white m-3 pb-4 row"
              style={{ borderRadius: "30px", overflow: "hidden" }}
            >
              <div class="loginImg col-md-6">
                <img alt="hey" src={travel} class="img-fluid" />
              </div>
              <div class="col-md-4">
                <div class="">
                  <h3 class="outletheading text-center bg-green text-white rounded-2 p-2">
                    My Travel Sarthi
                  </h3>

                  <Form class="login-form" onSubmit={(e) => handleSubmit(e)}>
                    {/* ...existing code */}
                    {forgotPasswordMode ? (
                      <>
                        <div class="mt-3">
                          <label class="mb-1 fw-bold mb-2" for="name">
                            Username
                          </label>
                          <input
                            type="text"
                            value={username}
                            onChange={(e) => setusername(e.target.value)}
                            className="form-control"
                          />
                        </div>
                        <div className="mt-3">
                          <label htmlFor="">Password</label>
                          <div className="input-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(e) => setpassword(e.target.value)}
                              className="form-control"
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() =>
                                handlePasswordVisibility("password")
                              }
                            >
                              {showPassword ? "Hide" : "Show"}
                            </button>
                          </div>
                        </div>
                        <div className="mt-3">
                          <label class="mb-1 fw-bold mb-2" for="newPassword">
                            New Password
                          </label>
                          <div className="input-group">
                            <input
                              type={showNewPassword ? "text" : "password"}
                              value={newPassword}
                              onChange={(e) => setnewPassword(e.target.value)}
                              className="form-control"
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() =>
                                handlePasswordVisibility("newPassword")
                              }
                            >
                              {showNewPassword ? "Hide" : "Show"}
                            </button>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="">
                      {forgotPasswordMode ? (
                        <button
                          className="btn text-primary px-0"
                          style={{ fontSize: "14px" }}
                          onClick={() => setForgotPasswordMode(false)}
                        >
                          Cancel
                        </button>
                      ) : (
                        <>
                          <div class="mt-3">
                            <label class="mb-1 fw-bold mb-2" for="name">
                              Username
                            </label>
                            <input
                              type="text"
                              value={username}
                              onChange={(e) => setusername(e.target.value)}
                              className="form-control"
                            />
                          </div>
                          <div className="mt-3">
                            <label htmlFor="">Password</label>
                            <div className="input-group">
                              <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setpassword(e.target.value)}
                                className="form-control"
                              />
                              <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={() =>
                                  handlePasswordVisibility("password")
                                }
                              >
                                {showPassword ? "Hide" : "Show"}
                              </button>
                            </div>
                          </div>
                          <button
                            className="btn text-primary px-0"
                            style={{ fontSize: "14px" }}
                            onClick={() => setForgotPasswordMode(true)}
                          >
                            Forgot Password ?
                          </button>
                        </>
                      )}
                    </div>
                    <button
                      class="btn mt-3 auth_btn w-100 bg-blue text-white"
                      type="submit"
                    >
                      {forgotPasswordMode ? "Reset Password" : "Login"}
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
