import React, { useEffect, useState } from "react";
import Sidebaar from "../Component/Sidebaar";
import TitleComp from "../Component/TitleComp";
import { Form, FormGroup } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { BASEURL } from "../BaseUrl";
import Loader from "../Component/Loader";
import { useParams } from "react-router-dom";

const HoteldetailDesc = () => {
  const [hoteldetail, sethoteldetail] = useState("");
  const [rows, setRows] = useState([{ id: 1, name: "", description: "" }]);
  const [open, setopen] = useState(false);
  const [countrydata, setcountrydata] = useState([]);
  const { id } = useParams();

  const allcountrydata = () => {
    axios.get(`${BASEURL}/allhotelsdetail`).then((res) => {
      console.log(res.data);
      setcountrydata(res.data);
    });
  };

  useEffect(() => {
    allcountrydata();
  }, []);
  useEffect(() => {
    if (id) {
      fetchHotelDetails();
    }
  }, [id]);

  const addRow = (e) => {
    e.preventDefault();
    const newRow = { id: rows.length + 1, name: "", description: "" };
    setRows([...rows, newRow]);
  };

  const removeRow = (e, id) => {
    e.preventDefault();
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };

  const handleNameChange = (e, id) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, name: e.target.value } : row
    );
    setRows(updatedRows);
  };

  const handleDescriptionChange = (data, id) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, description: data } : row
    );
    setRows(updatedRows);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setopen(true);

    const requestData = {
      hoteldetail: hoteldetail,
      details: rows.map((row) => ({ title: row.name, para: row.description })),
    };

    if (id) {
      requestData._id = id;
      axios
        .put(`${BASEURL}/hoteldetaildescs/${id}`, requestData)
        .then((res) => {
          if (res.data !== null) {
            setopen(false);
            // Optionally, reset form or perform other actions on success
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          setopen(false);
          // Optionally, handle errors differently
        });
    } else {
      axios
        .post(`${BASEURL}/hoteldetaildescs`, requestData)
        .then((res) => {
          if (res.data !== null) {
            setopen(false);
            // Optionally, reset form or perform other actions on success
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          setopen(false);
          // Optionally, handle errors differently
        });
    }
  };

  const fetchHotelDetails = () => {
    axios
      .get(`${BASEURL}/hoteldetaildescs/${id}`)
      .then((res) => {
        console.log("Response data:", res.data); // Log the response data

        const { detail, hoteldetail } = res.data || {};

        console.log("hoteldetail:", hoteldetail);
        console.log("detail:", detail);

        if (!hoteldetail) {
          console.log("Hoteldetail is not defined:", hoteldetail);
          return;
        }

        sethoteldetail(hoteldetail);

        if (detail && Array.isArray(detail)) {
          const updatedRows = detail.map((item) => ({
            id: item._id,
            name: item.title,
            description: item.para,
          }));

          console.log("updatedRows:", updatedRows);

          setRows(updatedRows);
        } else {
          console.log("Detail is not an array or is undefined:", detail);
        }
      })
      .catch((error) => {
        console.error("Error fetching hotel details:", error);
      });
  };

  return (
    <>
      <Loader open={open} />
      <Sidebaar
        content={
          <>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <TitleComp title={"Hotel Detail Description"} />
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <div className="col-md-4">
                      <FormGroup>
                        <label htmlFor="" className="fw-bold">
                          Select Hotel Detail
                        </label>
                        <select
                          name=""
                          value={hoteldetail}
                          onChange={(e) => sethoteldetail(e.target.value)}
                          className="form-control form-select"
                          id=""
                        >
                          <option value="" className="d-none">
                            Select hotelDetail
                          </option>
                          {countrydata?.map((item) => {
                            return (
                              <>
                                <option value={item._id} className="">
                                  {item.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </FormGroup>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <button
                          className="btn me-3 bg-dark text-white"
                          onClick={addRow}
                        >
                          Add Row
                        </button>
                        <button
                          className="btn bg-dark text-white"
                          onClick={() => removeRow(rows.length)}
                        >
                          Remove Row
                        </button>
                      </div>
                      {rows.map((row) => (
                        <div key={row.id} className="row mt-3">
                          <div className="col-md-4">
                            <FormGroup>
                              <label htmlFor="">Title</label>
                              <input
                                type="text"
                                className="form-control"
                                value={row.name}
                                onChange={(e) => handleNameChange(e, row.id)}
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-8">
                            <label htmlFor="">Description</label>
                            <CKEditor
                              editor={ClassicEditor}
                              data={row.description}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                handleDescriptionChange(data, row.id);
                              }}
                              className="custom-ckeditor"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <button className="btn bg-dark text-white">Submit</button>
                  </Form>
                </div>
              </div>
            </section>
          </>
        }
      />
    </>
  );
};

export default HoteldetailDesc;
