import React, { useEffect, useState } from "react";

import { Form, FormGroup, Modal } from "react-bootstrap";
import axios from "axios";
import { BASEURL } from "../BaseUrl";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useNavigate } from "react-router-dom";
import Loader from "../Component/Loader";
import TitleComp from "../Component/TitleComp";
import Sidebaar from "../Component/Sidebaar";

function HotelDetailList() {
  const [detaildata, setdetaildata] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);

  const [hotel, sethotel] = useState("");
  const [amenities, setAmenities] = useState([
    { name: "" }, // Initial row
  ]);

  const headers = {
    "Content-Type": "application/json",
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const alldetdata = () => {
    setOpen(true);
    axios
      .get(`${BASEURL}/allhotelsdetail`, {
        headers,
      })
      .then((res) => {
        console.log(res.data.hotels);
        if (res.data !== null) {
          setOpen(false);
        }
        setdetaildata(res.data);
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const navigate = useNavigate();
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },

    {
      field: "description",
      headerName: "description",
      flex: 1,
    },

    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <button className="btn" onClick={() => navigate(`/detail/${id}`)}>
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        );
      },
    },
    {
      field: "desc",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => {
        const id = params.row?.hoteldetaildesc[0]?._id;
        return (
          <button onClick={() => navigate(`/detaildesc/${id}`)} className="btn">
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <button onClick={() => handleDelete(id)} className="btn">
            <i class="fa-solid fa-trash"></i>
          </button>
        );
      },
    },
  ];

  const getRowId = (resident) => resident._id;

  useEffect(() => {
    alldetdata();
  }, [page, limit]);

  const handleDelete = (id) => {
    axios.delete(`${BASEURL}/hotelsdetail/${id}`).then((res) => {
      alert("delete Data Succesffully");
      alldetdata();
    });
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <Loader open={open} />
      <Sidebaar
        content={
          <>
            <section>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <TitleComp title="Hotels Details List" />
                  </div>
                </div>

                <div className="col-md-12 mt-3">
                  {detaildata.length > 0 ? (
                    <Box sx={{ height: 500, width: "100%" }}>
                      <DataGrid
                        rows={detaildata}
                        columns={columns}
                        getRowId={getRowId}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                      />
                    </Box>
                  ) : (
                    <div>Loading or no data available</div>
                  )}
                </div>
                {/* <div className="">
                  <button className="btn" onClick={handlePrevious}>
                    Previous
                  </button>
                  <button className="btn" onClick={handleNext}>
                    Next
                  </button>
                </div> */}
              </div>
            </section>
          </>
        }
      />
    </>
  );
}

export default HotelDetailList;
